import { css, html } from 'lit';
import { ReportFilingFormSection } from '../mixin';
import './shared/report-filing-details-form-section';
import '../../../pli/pli-textarea';
import { styles } from 'pli/styles';
import { customElement } from 'lit/decorators.js';

@customElement('report-filing-details-person-customer-activity')
class ReportFilingDetailsActivity extends ReportFilingFormSection {
    static styles = [styles.grid, css``];
    render() {
        const data = this.data;
        const reportPartyTileInfo = 'This information is prefilled from the Customer profile';
        return html` <report-filings-details-form-section heading="Activity">
            <div class="grid-vertical gap-1">
                <pli-text as="p"
                    >An activity represent an event where a list of parties and goods are related directly to the report
                    without the need of a transaction.
                </pli-text>
                <pli-text variant="h4">Reporting party information</pli-text>
                <pli-text variant="h4">Person customer</pli-text>
                <div class="grid gap-1">
                    <div class="col-span-4 grid-vertical gap-05">
                        <label>
                            <pli-text><strong>First name</strong></pli-text>
                        </label>
                        <pli-input
                            .disabled="${true}"
                            .title="${reportPartyTileInfo}"
                            value="${data.customer.personCustomer?.firstName}"
                        ></pli-input>
                    </div>
                    <div class="col-span-4 grid-vertical gap-05">
                        <label>
                            <pli-text><strong>Last name</strong></pli-text>
                        </label>
                        <pli-input
                            .disabled="${true}"
                            .title="${reportPartyTileInfo}"
                            value="${data.customer.personCustomer?.lastName}"
                        ></pli-input>
                    </div>
                    <div class="col-span-4 grid-vertical gap-05">
                        <label>
                            <pli-text><strong>Birthdate</strong></pli-text>
                        </label>
                        <pli-input
                            .disabled="${true}"
                            .title="${reportPartyTileInfo}"
                            type="date"
                            value="${data.customer.personCustomer?.birthdate}"
                        ></pli-input>
                    </div>
                </div>
            </div>
        </report-filings-details-form-section>`;
    }
}
