import { css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

import './shared/report-filing-details-form-section';
import './shared/report-filing-details-form-error-message';
import '../../../pli/pli-input';

import { PliInputChangeEvent } from 'pli/controllers/input-controller';

import { styles } from 'pli/styles';
import { ReportFilingFormSection } from '../mixin';

@customElement('report-filing-details-agent-information')
class ReportFilingDetailsAgentInformation extends ReportFilingFormSection {
    static styles = [styles.grid, styles.flex, styles.base, css``];

    render() {
        const { setPayload, issues, payload, getStateForField, isInvalidField, renderErrorMessageForField } = this;

        return html` <report-filings-details-form-section heading="Report agent information">
            <div class="grid gap-1">
                <div class="col-span-6 grid-vertical gap-05">
                    <label>
                        <pli-text><strong>Reporting user code*</strong></pli-text>
                    </label>
                    <pli-input
                        maxlength="50"
                        value="${payload?.agentReportingUserCode}"
                        state="${getStateForField('agentReportingUserCode')}"
                        @change="${(event: PliInputChangeEvent) =>
                            setPayload({
                                agentReportingUserCode: event.detail.value,
                            })}"
                    ></pli-input>
                </div>
                ${isInvalidField('agentReportingUserCode')
                    ? html` <div class="col-span-12">${renderErrorMessageForField('agentReportingUserCode')}</div> `
                    : null}
            </div>
        </report-filings-details-form-section>`;
    }
}
