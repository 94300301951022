import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { cleanParamsObject } from 'utils/params';

@autoinject
export class ReportDetails {
    httpClient: HttpClient;
    id: string;
    json: any;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    activate(params) {
        params = cleanParamsObject(params);
        this.id = params.id;
    }

    async attached() {
        let response: any = await this.httpClient.get(`Transactions/${this.id}`);
        let json: string = await response.json();

        this.json = JSON.stringify(json, null, 2);
    }
}
