import { css, html } from 'lit';
import { ReportFilingFormSection } from '../mixin';
import './shared/report-filing-details-form-section';
import '../../../pli/pli-textarea';
import '../../../pli/pli-checkbox';
import { styles } from 'pli/styles';
import { customElement } from 'lit/decorators.js';
import { riskIndicators, suspiciousIndicator } from '../config/indicators';
import { PliCheckboxUpdateEvent } from '../../../pli/pli-checkbox';
import { IndicatorsSchemaKey } from '../context/report-filings-payload-context';

@customElement('report-filing-details-indicators')
class ReportFilingDetailsIndicators extends ReportFilingFormSection {
    static styles = [styles.grid, styles.list, css``];
    render() {
        const { payload, setPayload, renderErrorMessageForField } = this;
        const isActive = <T>(name: T, types: T[]) => types?.includes(name);

        const onChange = <T>(detail: PliCheckboxUpdateEvent['detail'], key: IndicatorsSchemaKey) => {
            const { checked, value } = detail as { checked: boolean; value: T };
            const indicatorTypes = payload[key] as T[];

            const updatedIndicatorTypes = checked
                ? [...indicatorTypes, value]
                : [...indicatorTypes.filter((type) => type !== value)];

            setPayload({ [`${key}`]: [...updatedIndicatorTypes] });
        };

        const renderSuspiciousIndicators = () => {
            return html`<div class="grid-vertical gap-2">
                <pli-text variant="h3">Report indicator</pli-text>
                <div class="grid-vertical gap-05">
                    <pli-text as="p" variant="h5">${suspiciousIndicator.title}</pli-text>
                    <pli-text as="p">${suspiciousIndicator.description}</pli-text>
                    <ul>
                        ${suspiciousIndicator.indicators.map(
                            (indicator) =>
                                html`<li>
                                    <pli-checkbox
                                        .checked="${isActive(indicator.name, payload?.suspiciousIndicators)}"
                                        value="${indicator.name}"
                                        @change="${(event: PliCheckboxUpdateEvent) =>
                                            onChange(event.detail, 'suspiciousIndicators')}"
                                    >
                                        <div class="grid">
                                            <div class="col-span-1">${indicator.name}</div>
                                            <div class="col-span-11">${indicator.description}</div>
                                        </div>
                                    </pli-checkbox>
                                </li>`,
                        )}
                    </ul>
                    <div>${renderErrorMessageForField('suspiciousIndicators')}</div>
                </div>
            </div>`;
        };

        const renderRiskIndicators = () => {
            return html`<div class="grid-vertical gap-2">
                <div class="grid-vertical gap-05">
                    <pli-text as="p" variant="h4">Risk indicators</pli-text>
                    <pli-text as="p"
                        >Other indicators are risk indicators. At least one risk indicator must be stated. Selected risk
                        indicators describe the grounds for suspicions arising in a reporting customer.
                    </pli-text>
                </div>
                ${riskIndicators.map(
                    (group) =>
                        html` <div class="grid-vertical gap-05">
                            <pli-text variant="h5">${group.title}</pli-text>
                            <ul>
                                ${group.indicators.map(
                                    (indicator) =>
                                        html`<li>
                                            <pli-checkbox
                                                .checked="${isActive(indicator.name, payload?.riskIndicators)}"
                                                value="${indicator.name}"
                                                @change="${(event: PliCheckboxUpdateEvent) =>
                                                    onChange(event.detail, 'riskIndicators')}"
                                            >
                                                <div class="grid">
                                                    <div class="col-span-1">${indicator.name}</div>
                                                    <div class="col-span-11">${indicator.description}</div>
                                                </div>
                                            </pli-checkbox>
                                        </li>`,
                                )}
                            </ul>
                        </div>`,
                )}
                <div>${renderErrorMessageForField('riskIndicators')}</div>
            </div>`;
        };

        return html`<report-filings-details-form-section>
            <div class="grid-vertical gap-2">
                <div>${renderSuspiciousIndicators()}</div>
                <div>${renderRiskIndicators()}</div>
            </div>
        </report-filings-details-form-section>`;
    }
}
