import { consume } from '@lit/context';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styles } from 'pli/styles';
import { reportFilingSavedDateContext } from '../context/report-filings-saved-context';
import { ReportFilingFormSection } from '../mixin';

import '../../../pli/pli-button';
import '../../../pli/pli-icon';
import '../../../pli/pli-input';
import { reportFilingStatus } from '../context/report-filings-payload-context';
import { when } from 'lit/directives/when.js';
import '../../../pli/pli-dialog';
import '../../../shared/status/displayed-status-change';

@customElement('report-filing-details-button-group')
class RenderButtonGroup extends ReportFilingFormSection {
    static styles = [
        styles.grid,
        styles.flex,
        css`
            .button-group {
                top: var(--button-group-top);
                align-self: flex-start;
            }
            .saved-date-wrapper {
                opacity: 0;
                margin-top: var(--saved-date-wrapper-negative-offset);
                text-align: center;
                padding: var(--size-0-5) 0;
                transition: opacity 150ms ease;
            }
            .visible {
                opacity: 1;
            }

            ul,
            li {
                padding: 0;
            }
        `,
    ];

    @property({ type: Boolean })
    isDialogOpen: boolean = false;

    @consume({ context: reportFilingSavedDateContext, subscribe: true })
    savedDate: string;

    @state()
    _showErrorsEnabled = false;

    handleDownloadDismiss() {
        this.dispatchEvent(new CustomEvent('change-status-dismiss', { composed: true }));
    }

    handleConfirmReportedClick() {
        this._showErrorsEnabled = true;
        this.dispatchEvent(new CustomEvent('confirm-reported', { composed: true }));
    }

    handleValidationBeforeDialogOpen() {
        this.dispatchEvent(new CustomEvent('validate-before-dialog-open', { composed: true }));
    }

    handleDownloadClick() {
        this._showErrorsEnabled = true;
        this.dispatchEvent(new CustomEvent('download-report', { composed: true }));
    }

    handleReopenDismiss() {
        this.dispatchEvent(new CustomEvent('reopen-report', { composed: true }));
    }

    handleDeleteDismiss() {
        this.dispatchEvent(new CustomEvent('delete-report', { composed: true }));
    }

    _renderDeleteDialog() {
        return html`<pli-dialog @primary-dismiss="${this.handleDeleteDismiss}">
            <pli-dialog-open-button slot="open-button" iconStart="trash" variant="destructive"
                >Delete report</pli-dialog-open-button
            >
            <pli-dialog-content>
                <pli-icon-box name="exclamation-circle" color="pink" slot="icon"></pli-icon-box>
                <pli-text variant="h3">Delete report</pli-text>
                <pli-text
                    >This report is about to be deleted, the decision is irreversible. A new decision needs to be made
                    to the case.
                </pli-text>
                <displayed-status-change type="case" from="Reporting" to="Investigating"></displayed-status-change>
            </pli-dialog-content>
            <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
            <pli-dialog-primary-button slot="primary-button" variant="destructive"
                >Delete report</pli-dialog-primary-button
            >
        </pli-dialog>`;
    }

    _renderDownloadDialog() {
        return html`
            <pli-dialog open @primary-dismiss="${this.handleDownloadDismiss}">
                <pli-dialog-content>
                    <pli-icon-box name="download" slot="icon"></pli-icon-box>
                    <pli-text variant="h3">Your .xml report is downloading</pli-text>
                    <pli-text
                        >Would you like to change the status of this report from In Progress to Reported?
                    </pli-text>
                    <displayed-status-change type="report" from="InProgress" to="Reported"></displayed-status-change>
                </pli-dialog-content>
                <pli-dialog-close-button slot="secondary-button">No, keep it</pli-dialog-close-button>
                <pli-dialog-primary-button slot="primary-button">Yes, change it</pli-dialog-primary-button>
            </pli-dialog>
        `;
    }

    _renderReopenDialog() {
        return html`<pli-dialog @primary-dismiss="${this.handleReopenDismiss}">
            <pli-dialog-open-button
                slot="open-button"
                variant="secondary"
                iconStart="arrow-counterclockwise"
                iconSize="16"
                >Reopen report</pli-dialog-open-button
            >
            <pli-dialog-content>
                <pli-icon-box name="arrow-counterclockwise" slot="icon"></pli-icon-box>
                <pli-text variant="h3">Reopen the report</pli-text>
                <pli-text
                    >Would you like to reopen the report and change the status from Reported to In Progress?
                </pli-text>
                <displayed-status-change type="report" from="Reported" to="InProgress"></displayed-status-change>
            </pli-dialog-content>
            <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
            <pli-dialog-primary-button slot="primary-button">Yes, reopen report</pli-dialog-primary-button>
        </pli-dialog>`;
    }

    get reportIsInProgress() {
        return this.payload.status === reportFilingStatus.enum.InProgress;
    }

    render() {
        const { _showErrorsEnabled, savedDate, payload, issues } = this;
        const savedDateWrapperClasses = classMap({
            'saved-date-wrapper': true,
            visible: Boolean(savedDate),
        });

        const showErrors = _showErrorsEnabled && Boolean(issues?.length);

        return html`<div class="">
            <div class="${savedDateWrapperClasses}">Saved: ${savedDate}</div>
            <div class="grid-vertical gap-05">
                ${when(
                    this.reportIsInProgress,
                    () => html`
                        <pli-button size="lg" width="full" .onClick="${() => this.handleValidationBeforeDialogOpen()}">
                            <pli-icon slot="icon-left" name="file-earmark-arrow-down"></pli-icon>
                            Download report
                        </pli-button>

                        ${when(this.isDialogOpen, () => this._renderDownloadDialog())}
                    `,
                    () =>
                        html`<pli-button size="lg" width="full" .onClick="${this.handleDownloadClick}">
                            <pli-icon slot="icon-left" name="file-earmark-arrow-down"></pli-icon>
                            Download report
                        </pli-button>`,
                )}
                ${when(
                    this.reportIsInProgress,
                    () =>
                        html`<pli-button
                            size="lg"
                            width="full"
                            .onClick="${() => this.handleConfirmReportedClick()}"
                            variant="secondary"
                        >
                            <pli-icon slot="icon-left" name="check2-circle"></pli-icon>
                            Confirm as reported
                        </pli-button>`,
                    () => this._renderReopenDialog(),
                )}
                ${this._renderDeleteDialog()}
                ${when(
                    showErrors,
                    () => html`
                        <pli-alert-box variant="error">
                            <div>
                                <pli-text>Report needs to be valid before downloadning.</pli-text>
                                <ul class="grid-vertical gap-1">
                                    ${issues.map((issue) => html`<li>${issue.message}</li>`)}
                                </ul>
                            </div>
                        </pli-alert-box>
                    `,
                )}
            </div>
        </div>`;
    }
}
