import { css } from 'lit';

export const paddingStyles = css`
    .p-0-125 {
        padding: var(--size-0-125);
    }
    .p-05 {
        padding: var(--size-0-5);
    }
    .p-075 {
        padding: var(--size-0-75);
    }
    .p-1 {
        padding: var(--size-1);
    }
    .p-1-5 {
        padding: var(--size-1-5);
    }
    .p-2 {
        padding: var(--size-2);
    }
    .p-2-5 {
        padding: var(--size-2-5);
    }
    .p-3 {
        padding: var(--size-3);
    }
    .p-3-5 {
        padding: var(--size-3-5);
    }
    .p-4 {
        padding: var(--size-4);
    }
    .p-4-5 {
        padding: var(--size-4-5);
    }
    .p-5 {
        padding: var(--size-5);
    }

    .px-0-125 {
        padding-left: var(--size-0-125);
        padding-right: var(--size-0-125);
    }
    .px-05 {
        padding-left: var(--size-0-5);
        padding-right: var(--size-0-5);
    }
    .px-075 {
        padding-left: var(--size-0-75);
        padding-right: var(--size-0-75);
    }
    .px-1 {
        padding-left: var(--size-1);
        padding-right: var(--size-1);
    }
    .px-1-5 {
        padding-left: var(--size-1-5);
        padding-right: var(--size-1-5);
    }
    .px-2 {
        padding-left: var(--size-2);
        padding-right: var(--size-2);
    }
    .px-2-5 {
        padding-left: var(--size-2-5);
        padding-right: var(--size-2-5);
    }
    .px-3 {
        padding-left: var(--size-3);
        padding-right: var(--size-3);
    }
    .px-3-5 {
        padding-left: var(--size-3-5);
        padding-right: var(--size-3-5);
    }
    .px-4 {
        padding-left: var(--size-4);
        padding-right: var(--size-4);
    }
    .px-4-5 {
        padding-left: var(--size-4-5);
        padding-right: var(--size-4-5);
    }
    .px-5 {
        padding-left: var(--size-5);
        padding-right: var(--size-5);
    }

    .py-0-125 {
        padding-top: var(--size-0-125);
        padding-bottom: var(--size-0-125);
    }
    .py-05 {
        padding-top: var(--size-0-5);
        padding-bottom: var(--size-0-5);
    }
    .py-075 {
        padding-top: var(--size-0-75);
        padding-bottom: var(--size-0-75);
    }
    .py-1 {
        padding-top: var(--size-1);
        padding-bottom: var(--size-1);
    }
    .py-1-5 {
        padding-top: var(--size-1-5);
        padding-bottom: var(--size-1-5);
    }
    .py-2 {
        padding-top: var(--size-2);
        padding-bottom: var(--size-2);
    }
    .py-2-5 {
        padding-top: var(--size-2-5);
        padding-bottom: var(--size-2-5);
    }
    .py-3 {
        padding-top: var(--size-3);
        padding-bottom: var(--size-3);
    }
    .py-3-5 {
        padding-top: var(--size-3-5);
        padding-bottom: var(--size-3-5);
    }
    .py-4 {
        padding-top: var(--size-4);
        padding-bottom: var(--size-4);
    }
    .py-4-5 {
        padding-top: var(--size-4-5);
        padding-bottom: var(--size-4-5);
    }
    .py-5 {
        padding-top: var(--size-5);
        padding-bottom: var(--size-5);
    }

    .pt-0-125 {
        padding-top: var(--size-0-125);
    }
    .pt-05 {
        padding-top: var(--size-0-5);
    }
    .pt-075 {
        padding-top: var(--size-0-75);
    }
    .pt-1 {
        padding-top: var(--size-1);
    }
    .pt-1-5 {
        padding-top: var(--size-1-5);
    }
    .pt-2 {
        padding-top: var(--size-2);
    }
    .pt-2-5 {
        padding-top: var(--size-2-5);
    }
    .pt-3 {
        padding-top: var(--size-3);
    }
    .pt-3-5 {
        padding-top: var(--size-3-5);
    }
    .pt-4 {
        padding-top: var(--size-4);
    }
    .pt-4-5 {
        padding-top: var(--size-4-5);
    }
    .pt-5 {
        padding-top: var(--size-5);
    }

    .pr-0-125 {
        padding-right: var(--size-0-125);
    }
    .pr-05 {
        padding-right: var(--size-0-5);
    }
    .pr-075 {
        padding-right: var(--size-0-75);
    }
    .pr-1 {
        padding-right: var(--size-1);
    }
    .pr-1-5 {
        padding-right: var(--size-1-5);
    }
    .pr-2 {
        padding-right: var(--size-2);
    }
    .pr-2-5 {
        padding-right: var(--size-2-5);
    }
    .pr-3 {
        padding-right: var(--size-3);
    }
    .pr-3-5 {
        padding-right: var(--size-3-5);
    }
    .pr-4 {
        padding-right: var(--size-4);
    }
    .pr-4-5 {
        padding-right: var(--size-4-5);
    }
    .pr-5 {
        padding-right: var(--size-5);
    }

    .pb-0-125 {
        padding-bottom: var(--size-0-125);
    }
    .pb-05 {
        padding-bottom: var(--size-0-5);
    }
    .pb-075 {
        padding-bottom: var(--size-0-75);
    }
    .pb-1 {
        padding-bottom: var(--size-1);
    }
    .pb-1-5 {
        padding-bottom: var(--size-1-5);
    }
    .pb-2 {
        padding-bottom: var(--size-2);
    }
    .pb-2-5 {
        padding-bottom: var(--size-2-5);
    }
    .pb-3 {
        padding-bottom: var(--size-3);
    }
    .pb-3-5 {
        padding-bottom: var(--size-3-5);
    }
    .pb-4 {
        padding-bottom: var(--size-4);
    }
    .pb-4-5 {
        padding-bottom: var(--size-4-5);
    }
    .pb-5 {
        padding-bottom: var(--size-5);
    }

    .pl-0-125 {
        padding-left: var(--size-0-125);
    }
    .pl-05 {
        padding-left: var(--size-0-5);
    }
    .pl-075 {
        padding-left: var(--size-0-75);
    }
    .pl-1 {
        padding-left: var(--size-1);
    }
    .pl-1-5 {
        padding-left: var(--size-1-5);
    }
    .pl-2 {
        padding-left: var(--size-2);
    }
    .pl-2-5 {
        padding-left: var(--size-2-5);
    }
    .pl-3 {
        padding-left: var(--size-3);
    }
    .pl-3-5 {
        padding-left: var(--size-3-5);
    }
    .pl-4 {
        padding-left: var(--size-4);
    }
    .pl-4-5 {
        padding-left: var(--size-4-5);
    }
    .pl-5 {
        padding-left: var(--size-5);
    }
`;
