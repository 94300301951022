import { css, html } from 'lit';
import { ReportFilingFormSection } from '../mixin';
import './shared/report-filing-details-form-section';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import { customElement } from 'lit/decorators.js';
import { styles } from 'pli/styles';
import 'pli/pli-select';
import { toReadable as toReadableCountryCode } from 'utils/country-code-formatter';
import { countryCodesEnum } from 'shared/schema/country-code-schema';

@customElement('report-filing-details-location')
class ReportFilingDetailsLocation extends ReportFilingFormSection {
    static styles = [styles.base, styles.flex, styles.grid, css``];
    render() {
        const { payload, setPayload, getStateForField, renderErrorMessageForField } = this;
        return html` <report-filings-details-form-section heading="Location">
            <div class="grid gap-1">
                <div class="col-span-6 grid-vertical gap-05">
                    <label>
                        <pli-text><strong>Address*</strong></pli-text>
                        <pli-text as="p">Street name and house number</pli-text>
                    </label>
                    <pli-input
                        value="${payload?.address}"
                        state="${getStateForField('address')}"
                        @change="${(event: PliInputChangeEvent) =>
                            setPayload({
                                address: event.detail.value,
                            })}"
                    ></pli-input>
                </div>
                <div class="col-span-6 grid-vertical gap-05">
                    <label>
                        <pli-text as="p"><strong>City*</strong></pli-text>
                        <pli-text as="p">&nbsp;</pli-text>
                    </label>
                    <pli-input
                        value="${payload?.city}"
                        state="${getStateForField('city')}"
                        @change="${(event: PliInputChangeEvent) =>
                            setPayload({
                                city: event.detail.value,
                            })}"
                    ></pli-input>
                </div>
            </div>
            <div class="grid gap-1">
                <div class="col-span-6 grid-vertical gap-05">
                    <label>
                        <pli-text><strong>Country*</strong></pli-text>
                    </label>
                    <pli-select
                        state="${getStateForField('countryCode')}"
                        placeholder="Select country"
                        .options="${countryCodesEnum.options.map((country) => ({
                            value: country,
                            label: toReadableCountryCode(country),
                        }))}"
                        value="${payload?.countryCode}"
                        size="md"
                        @change="${(event: CustomEvent) => setPayload({ countryCode: event.detail.value })}"
                    ></pli-select>
                </div>
                <div class="col-span-12">
                    ${renderErrorMessageForField('address')} ${renderErrorMessageForField('city')}
                    ${renderErrorMessageForField('countryCode')}
                </div>
            </div>
        </report-filings-details-form-section>`;
    }
}
