import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

import '../../../../pli/pli-alert-box';

@customElement('report-filing-details-form-error-message')
class ReportFilingDetailsFormErrorMessage extends LitElement {
    static styles = [
        css`
            .error {
                display: block;
                margin-top: var(--size-0-5);
            }
        `,
    ];
    render() {
        return html`<div class="error">
            <pli-alert-box variant="error"><slot></slot></pli-alert-box>
        </div>`;
    }
}
