import { css } from 'lit';

export const roundedStyles = css`
    .rounded-0 {
        border-radius: 0;
    }
    .rounded-sm {
        border-radius: var(--radius-sm);
    }
    .rounded-md {
        border-radius: var(--radius-md);
    }
    .rounded-lg {
        border-radius: var(--radius-lg);
    }
    .rounded-full {
        border-radius: var(--radius-full);
    }

    /* rounded top left */
    .rounded-t-l-0 {
        border-top-left-radius: var(--radius-0);
    }
    .rounded-t-l-sm {
        border-top-left-radius: var(--radius-sm);
    }
    .rounded-t-l-md {
        border-top-left-radius: var(--radius-md);
    }
    .rounded-t-l-lg {
        border-top-left-radius: var(--radius-lg);
    }
    .rounded-t-l-full {
        border-top-left-radius: var(--radius-full);
    }

    /* rounded top right */
    .rounded-t-r-0 {
        border-top-right-radius: var(--radius-0);
    }
    .rounded-t-r-sm {
        border-top-right-radius: var(--radius-sm);
    }
    .rounded-t-r-md {
        border-top-right-radius: var(--radius-md);
    }
    .rounded-t-r-lg {
        border-top-right-radius: var(--radius-lg);
    }
    .rounded-t-r-full {
        border-top-right-radius: var(--radius-full);
    }

    /* rounded bottom right */
    .rounded-b-r-0 {
        border-bottom-right-radius: var(--radius-0);
    }
    .rounded-b-r-sm {
        border-bottom-right-radius: var(--radius-sm);
    }
    .rounded-b-r-md {
        border-bottom-right-radius: var(--radius-md);
    }
    .rounded-b-r-lg {
        border-bottom-right-radius: var(--radius-lg);
    }
    .rounded-b-r-full {
        border-bottom-right-radius: var(--radius-full);
    }

    /* rounded bottom left */
    .rounded-b-l-0 {
        border-bottom-left-radius: var(--radius-0);
    }
    .rounded-b-l-sm {
        border-bottom-left-radius: var(--radius-sm);
    }
    .rounded-b-l-md {
        border-bottom-left-radius: var(--radius-md);
    }
    .rounded-b-l-lg {
        border-bottom-left-radius: var(--radius-lg);
    }
    .rounded-b-l-full {
        border-bottom-left-radius: var(--radius-full);
    }
`;
