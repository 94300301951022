// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* imported from Pliance Design System 2024-10-29 */

:root {
    --base-value: 1rem;
    --radius-none: calc(0 / 16 * var(--base-value));
    --radius-1: calc(4 / 16 * var(--base-value));
    --radius-2: calc(8 / 16 * var(--base-value));
    --radius-3: calc(12 / 16 * var(--base-value));
    --radius-4: calc(16 / 16 * var(--base-value));
}
`, "",{"version":3,"sources":["webpack://./src/pli/tokens/radius.css"],"names":[],"mappings":"AAAA,mDAAmD;;AAEnD;IACI,kBAAkB;IAClB,+CAA+C;IAC/C,4CAA4C;IAC5C,4CAA4C;IAC5C,6CAA6C;IAC7C,6CAA6C;AACjD","sourcesContent":["/* imported from Pliance Design System 2024-10-29 */\n\n:root {\n    --base-value: 1rem;\n    --radius-none: calc(0 / 16 * var(--base-value));\n    --radius-1: calc(4 / 16 * var(--base-value));\n    --radius-2: calc(8 / 16 * var(--base-value));\n    --radius-3: calc(12 / 16 * var(--base-value));\n    --radius-4: calc(16 / 16 * var(--base-value));\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
