import { css } from 'lit';

export const listStyles = css`
    ul,
    li {
        padding: 0;
        margin: 0;
    }

    li {
        list-style-type: none;
    }

    li + li {
        border-top: 1px solid var(--color-light-gray);
    }
`;
