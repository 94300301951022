import { css } from 'lit';

export const dividerStyles = css`
    .divider-y > * + * {
        border-top: var(--border-default);
    }

    .divider-x > * + * {
        border-left: var(--border-default);
    }
`;
