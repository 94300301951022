import { z } from 'zod';
import { countryCodesEnum } from 'shared/schema/country-code-schema';
import { GetReportFilingResponse, ContactTypeSchema, ReportTypeSchema, UpdateReportFiling_Request } from 'tms-client';
import { createContext } from '@lit/context';

const _requiredStringValue = (message: string = 'Required field') =>
    z.string({ invalid_type_error: message }).min(1, { message });
const _requiredNumberValue = (message: string = '') => z.number().positive({ message });
const _optionalStringValue = z.string().optional().nullable();

export const SUSPICIOUS_INDICATORS = ['AMTPT', 'BMTTF'] as const;
export const suspiciousIndicatorsEnum = z.enum(SUSPICIOUS_INDICATORS);
export type SuspiciousIndicator = z.infer<typeof suspiciousIndicatorsEnum>;

export const RISK_INDICATORS = [
    'GEO01',
    'GEO02',
    'GEO03',
    'GEO04',
    'INF01',
    'INF02',
    'INF03',
    'INF04',
    'INF05',
    'INF06',
    'KAN01',
    'KAN02',
    'KAN03',
    'KDK01',
    'KDK02',
    'KDK03',
    'KDK04',
    'KDK05',
    'KDK06',
    'KDK07',
] as const;
export const riskIndicatorsEnum = z.enum(RISK_INDICATORS);
export type RiskIndicator = z.infer<typeof riskIndicatorsEnum>;

const indicatorsEnum = z.intersection(riskIndicatorsEnum, suspiciousIndicatorsEnum);

const REPORT_FILING_STATUS = ['InProgress', 'Reported'] as const;
export const reportFilingStatus = z.enum(REPORT_FILING_STATUS);
export type ReportFilingStatus = z.infer<typeof reportFilingStatus>;

export const availableCurrencies = ['EUR', 'SEK', 'USD'] as const;
export const currencyEnum = z.enum(availableCurrencies);

type ValidationSchema<T> = z.ZodObject<{ [key in keyof T]: z.ZodType<T[key]> }>;

export const validationSchema: ValidationSchema<UpdateReportFiling_Request> = z.object({
    filingName: _requiredStringValue('Name is required'),
    description: _optionalStringValue,
    reportingCustomerId: _requiredNumberValue('goAML reporting customer ID is required'),
    localCurrencyCode: currencyEnum,
    submissionDateUtc: _requiredStringValue().datetime(
        'Invalid date. Submission date needs to follow format: YYYY-MM-DD',
    ),
    agentReportingUserCode: _requiredStringValue('Reporting user code is required.').max(50, {
        message: 'Reporting user code can be max 50 characters.',
    }),
    address: _requiredStringValue('Address is required'),
    reportFilingId: _requiredNumberValue(),
    reason: _requiredStringValue('Reason for reporting is required').max(4000, {
        message: 'Reason for reporting can be max 4000 characters.',
    }),
    customerId: _requiredStringValue(),
    reportCode: z.enum([...ReportTypeSchema.enum]).optional(),
    reportType: _optionalStringValue,
    addressType: z.enum([...ContactTypeSchema.enum]).optional(),
    city: _optionalStringValue,
    countryCode: countryCodesEnum,
    actions: _requiredStringValue('Actions taken or planned is required').max(4000, {
        message: 'Actions taken or planned can be max 4000 characters.',
    }),
    status: reportFilingStatus,
    reportIndicatorTypes: z.array(indicatorsEnum),
});

//For being able to validate risk and suspicious indicators separately in form
export const indicatorsSchema = z.object({
    riskIndicators: z.array(riskIndicatorsEnum).min(1, { message: 'At least one risk indicator is required.' }),
    suspiciousIndicators: z
        .array(suspiciousIndicatorsEnum)
        .min(1, { message: 'At least one suspicious indicator is required.' }),
});
export const indicatorsSchemaKey = indicatorsSchema.keyof();
export const modifiedReportFilingValidationSchema = validationSchema
    .omit({ reportIndicatorTypes: true })
    .merge(indicatorsSchema);
export const keys = modifiedReportFilingValidationSchema.keyof();
export const modifiedValidationPartial = modifiedReportFilingValidationSchema.partial();

export type ReportFilingValidationKey = z.infer<typeof keys>;
export type IndicatorsSchemaKey = z.infer<typeof indicatorsSchemaKey>;
export type ReportFilingValidation = z.infer<typeof modifiedReportFilingValidationSchema>;

export type ReportFilingValidationPartial = z.infer<typeof modifiedValidationPartial>;
export type ReportFilingValidationMutation = (payload: Partial<ReportFilingValidation>) => void;

export const reportFilingValidationContext = createContext<ReportFilingValidation>('report-filing-validation');
export const reportFilingValidationMutationContext = createContext<ReportFilingValidationMutation>(
    'report-filing-validation-mutation',
);

export function mapReportFilingValidationIndicators(indicators: GetReportFilingResponse['reportIndicatorTypes']): {
    riskIndicators: RiskIndicator[];
    suspiciousIndicators: SuspiciousIndicator[];
} {
    return {
        riskIndicators: (indicators as RiskIndicator[]).filter((i) =>
            riskIndicatorsEnum.options.includes(i as RiskIndicator),
        ),
        suspiciousIndicators: (indicators as SuspiciousIndicator[]).filter((i) =>
            suspiciousIndicatorsEnum.options.includes(i),
        ),
    };
}
