import { RiskIndicator, SuspiciousIndicator } from '../context/report-filings-payload-context';

export const suspiciousIndicator: {
    title: string;
    description: string;
    indicators: { name: SuspiciousIndicator; description: string }[];
} = {
    title: 'Suspicious indicator',
    description:
        'At least one of the two indicators of suspicion must be stated for SAR and STR. Not to be used for AIF, AIFT, RIF and RIF_T.',
    indicators: [
        { name: 'BMTTF', description: 'Suspicion of Terror financing' },
        { name: 'AMTPT', description: 'Suspicion of Money laundering' },
    ],
};

export const riskIndicators: { title: string; indicators: { name: RiskIndicator; description: string }[] }[] = [
    {
        title: 'Geographical indicators',
        indicators: [
            {
                name: 'GEO01',
                description:
                    'Area or state which doesn’t have efficient systems for combating money laundering or terrorist financing.',
            },
            {
                name: 'GEO02',
                description: 'Area or state with considerable corruption and other relevant types of crime.',
            },
            {
                name: 'GEO03',
                description:
                    'An area where a state finances or supports terrorist activities or where terrorist organizations operate.',
            },
            { name: 'GEO04', description: 'Other geographical area.' },
        ],
    },
    {
        title: 'Information indicator',
        indicators: [
            {
                name: 'INF01',
                description: 'Course of action, modus operandi, trends, national risk assessments.',
            },
            {
                name: 'INF02',
                description: 'Specific information from another reporting customer or source.',
            },
            {
                name: 'INF03',
                description: 'Sanctions, embargo or similar actions.',
            },
            {
                name: 'INF04',
                description: 'Specific information from a law enforcement agency.',
            },
            { name: 'INF05', description: 'Other information.' },
            {
                name: 'INF06',
                description: 'Specific information about probable proceedings of a crime.',
            },
        ],
    },
    {
        title: 'Distribution channel indicator',
        indicators: [
            {
                name: 'KAN01',
                description:
                    'Business relations or transactions are conducted at a distance, without the use of methods which can verify the client’s identity (anonymization).',
            },
            {
                name: 'KAN02',
                description:
                    'The payment of goods or services is done by someone unknown or someone who’s not linked to the client.',
            },
            { name: 'KAN03', description: 'Other distribution channels.' },
        ],
    },
    {
        title: 'Know your customer indicator',
        indicators: [
            {
                name: 'KDK01',
                description: 'The client has supposedly given none or incorrect information.',
            },
            {
                name: 'KDK02',
                description: 'The client acts supposedly on behalf of another person.',
            },
            {
                name: 'KDK03',
                description:
                    'The information from the client seems unusual or too complex in relation to its business.',
            },
            {
                name: 'KDK04',
                description: 'The client is new or changes it’s activity which makes a great impact on the KYC.',
            },
            {
                name: 'KDK05',
                description:
                    'The client uses a product/service or conducts a transaction in a different way than anticipated.',
            },
            {
                name: 'KDK06',
                description:
                    'The client uses a product/service or conducts a transaction in a way that deviates from other clients within the same category.',
            },
            {
                name: 'KDK07',
                description: 'Other information about a client.',
            },
        ],
    },
];
