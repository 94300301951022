import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles } from 'pli/styles';

import 'pli/pli-button';
import 'pli/pli-icon';
import 'pli/pli-card';
import 'pli/pli-drag-drop';
import 'pli/pli-status-label';
import 'pli/pli-user-bubble';

import { consume, provide } from '@lit/context';
import { Task } from '@lit/task';
import { getFormattedLocalTime } from 'utils/datetime-formatter';
import { CurrentUser, currentUserContext } from 'context/current-user-context';
import { UserModel } from 'user-model';
import { downloadContent } from 'utils/download-content';
import { Issue, ValidationController } from 'controllers/validation-controller';
import db from 'just-debounce';
import { dateFormats } from 'utils/date';
import { Router, routerContext } from 'context/router-context';

import {
    mapReportFilingValidationIndicators,
    modifiedReportFilingValidationSchema,
    reportFilingStatus,
    ReportFilingValidation,
    reportFilingValidationContext,
    ReportFilingValidationMutation,
    reportFilingValidationMutationContext,
} from './context/report-filings-payload-context';
import { reportFilingSavedDateContext } from './context/report-filings-saved-context';
import { reportFilingIssuesContext } from './context/report-filings-issues-context';

import './components/shared/report-filing-details-form-section';
import './components/report-filing-details-button-group';
import './components/report-filing-details-general-information';
import './components/report-filing-details-location';
import './components/report-filing-details-reason';
import './components/report-filing-details-actions';
import './components/report-filing-details-indicators';
import './components/report-filing-details-documents';
import './components/report-filing-details-agent-information';
import './components/report-filing-details-person-customer-activity';
import './components/report-filing-details-organization-customer-activity';

import { when } from 'lit/directives/when.js';
import {
    deleteReportFiling,
    downloadZipReportFilingDocuments,
    getReportFiling,
    GetReportFiling_Result,
    updateReportFiling,
} from 'tms-client';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';
import { reportFilingResultContext } from './context/report-filings-result-context';

@customElement('report-filings-details')
class ReportFilingsDetails extends LitElement {
    static styles = [
        styles.grid,
        styles.flex,
        styles.base,
        styles.position,
        styles.padding,
        styles.divider,
        styles.list,
        styles.form,
        css`
            :host {
                --button-group-top: var(--size-2-5);
                --saved-date-wrapper-negative-offset: calc(var(--button-group-top) * -1);
            }
            fieldset {
                appearance: none;
                border: 0;
            }

            .button-group {
                top: var(--button-group-top);
                align-self: flex-start;
            }

            @keyframes appear {
                from {
                    opacity: 0;
                    transform: translateY(var(--size-1));
                }

                to {
                    opacity: 1;
                    transform: none;
                }
            }

            .error {
                display: block;
                margin-top: var(--size-0-5);
            }
        `,
    ];

    @property()
    reportfilingid: string;

    @provide({ context: reportFilingValidationContext })
    payload: ReportFilingValidation;

    @provide({ context: reportFilingSavedDateContext })
    savedDate: string;

    @provide({ context: reportFilingValidationMutationContext })
    payloadMutation: ReportFilingValidationMutation;

    @provide({ context: reportFilingIssuesContext })
    issues: Issue[];

    @provide({ context: reportFilingResultContext })
    data: GetReportFiling_Result;

    @consume({ context: currentUserContext })
    public currentUser: CurrentUser;

    @consume({ context: routerContext })
    public router: Router;

    @consume({ context: errorDialogContext })
    public errorDialog: ErrorDialogController;

    @state()
    _profile: UserModel = new UserModel();

    @state()
    _savedDate: string | null = null;

    @state()
    _downloadDialogOpen = false;

    _schema = modifiedReportFilingValidationSchema;

    _validationController = new ValidationController(this, this._schema);

    _task = new Task(this, {
        task: async ([]) => {
            const { data: _data, error } = await getReportFiling({
                path: { id: Number(this.reportfilingid) },
            });

            if (error) {
                return this.errorDialog.showError({ title: 'Could not retrieve report' });
            }

            this.data = _data;
            this.setValidationData(this.data);
        },
        args: () => [],
    });

    async connectedCallback() {
        super.connectedCallback();
        this._profile = await this.currentUser.get();
        this.payloadMutation = this._handlePayloadChange;
    }

    _saveWithDebounce = db(async () => {
        const { error } = await updateReportFiling({
            path: { id: Number(this.reportfilingid) },
            body: {
                ...this.payload,
                filingName: this.payload.filingName,
                reportIndicatorTypes: [...this.payload.suspiciousIndicators, ...this.payload.riskIndicators],
            },
            parseAs: 'stream',
        });
        if (error) {
            return this.errorDialog.showError({ bodyText: 'There was an error while trying to auto save the report.' });
        }
        this.displaySavedNotification();
    }, 1000);

    private displaySavedNotification() {
        this.savedDate = getFormattedLocalTime(new Date(), dateFormats.time);

        window.setTimeout(() => {
            this.savedDate = null;
        }, 10000);
    }

    get isFormEnabled() {
        return this.payload?.status === reportFilingStatus.Enum.InProgress;
    }

    get isValid() {
        return !this._validationController.issues.length;
    }

    _handlePayloadChange = (payload: Partial<ReportFilingValidation>) => {
        this.payload = { ...this.payload, ...payload };
        const { isValid } = this._validationController.parsePartial(payload);
        this.issues = [...this._validationController.issues];

        if (!isValid) {
            return;
        }

        this._saveWithDebounce();
    };

    setValidationData(data: GetReportFiling_Result) {
        const { riskIndicators, suspiciousIndicators } = mapReportFilingValidationIndicators(data.reportIndicatorTypes);
        this.payload = {
            ...data,
            suspiciousIndicators,
            riskIndicators,
        };
    }

    /* Button actions */

    validateReportPayload() {
        const { isValid } = this._validationController.parse(this.payload);
        this.issues = [...this._validationController.issues];

        if (!isValid) {
            return;
        }

        this._downloadDialogOpen = true;
        this.downloadReport();
    }

    changeReportStatus() {
        this._handlePayloadChange({ status: reportFilingStatus.Enum.Reported });
        this._downloadDialogOpen = false;
    }

    downloadReport = async () => {
        const { error, response } = await downloadZipReportFilingDocuments({
            path: { id: Number(this.reportfilingid) },
            parseAs: 'stream',
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not download documents' });
        }

        const content = await response.blob();
        const name = `report-filing-${this.reportfilingid}.zip`;

        downloadContent(content, name);
    };

    closeReport = async (): Promise<void> => {
        const { isValid } = this._validationController.parse(this.payload);
        this.issues = [...this._validationController.issues];

        if (!isValid) {
            return;
        }

        this._handlePayloadChange({ status: reportFilingStatus.enum.Reported });
    };

    reopenReport = async () => {
        this._handlePayloadChange({ status: reportFilingStatus.enum.InProgress });
    };

    deleteReport = async () => {
        const { error } = await deleteReportFiling({ path: { id: Number(this.reportfilingid) }, parseAs: 'stream' });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not delete report' });
        }

        this.router.navigateToRoute('report-filing');
    };

    renderStatusLabel(): TemplateResult {
        if (this.payload?.status) {
            return html`<pli-status-label variant="${this.payload?.status}"></pli-status-label>`;
        }
    }

    render() {
        const { isFormEnabled } = this;

        if (!this.data) {
            return null;
        }

        const { customer, customerId } = this.data;
        const isPersonCustomer = customer.type === 'Person';

        return html`
            <div class="grid gap-1">
                <div class="col-span-12">
                    <pli-button variant="text" as="a" href="/report-filing">
                        <pli-icon name="arrow-left-circle" slot="icon-left"></pli-icon>
                        Back to reports
                    </pli-button>
                </div>
                <div class="col-span-10 gap-1">
                    <div class="flex justify-between ">
                        <div>
                            <pli-text as="h1" variant="h1">Report</pli-text>
                            <pli-text as="p">
                                Report created from case:
                                <a href="/customers/${customerId}/cases/${this.data?.case?.caseId}/#investigations"
                                    >${this.data?.case.name}</a
                                >
                            </pli-text>
                        </div>
                        <pli-card
                            padding="collapsed"
                            bordertype="default"
                            background="none"
                            boxshadow="none"
                            width="hug-content"
                        >
                            <div class="flex divider-x">
                                <div class="flex items-center gap-05 py-075 px-1 ">
                                    Status ${this.renderStatusLabel()}
                                </div>
                                <div class="flex items-center gap-05 py-075 px-1">
                                    Created by
                                    <pli-user-bubble
                                        .firstName="${this.data?.createdBy.firstName}"
                                        .lastName="${this.data?.createdBy.lastName}"
                                        .userId="${this.data?.createdBy.userId}"
                                    ></pli-user-bubble>
                                </div>
                            </div>
                        </pli-card>
                    </div>
                </div>
                <div class="col-span-12 grid gap-1 relative">
                    <div class="col-span-10">
                        <form class="grid-vertical gap-2" .inert="${!isFormEnabled}">
                            <pli-card>
                                <div class="grid-vertical gap-4">
                                    <report-filing-details-general-information></report-filing-details-general-information>
                                    <report-filing-details-agent-information></report-filing-details-agent-information>
                                    <report-filing-details-location></report-filing-details-location>
                                    <report-filing-details-reason></report-filing-details-reason>
                                    <report-filing-details-actions></report-filing-details-actions>
                                    ${when(
                                        isPersonCustomer,
                                        () =>
                                            html`<report-filing-details-person-customer-activity></report-filing-details-person-customer-activity>`,
                                        () =>
                                            html`<report-filing-details-organization-customer-activity></report-filing-details-organization-customer-activity>`,
                                    )}
                                </div>
                            </pli-card>
                            <pli-card>
                                <report-filing-details-indicators></report-filing-details-indicators>
                            </pli-card>
                            <pli-card>
                                <report-filing-details-documents></report-filing-details-documents>
                            </pli-card>
                        </form>
                    </div>
                    <div class="col-span-2 button-group sticky">
                        <report-filing-details-button-group
                            @confirm-reported="${this.closeReport}"
                            @validate-before-dialog-open="${this.validateReportPayload}"
                            @change-status-dismiss="${this.changeReportStatus}"
                            @download-report="${this.downloadReport}"
                            @reopen-report="${this.reopenReport}"
                            @delete-report="${this.deleteReport}"
                            .isDialogOpen="${this._downloadDialogOpen}"
                        ></report-filing-details-button-group>
                    </div>
                </div>
            </div>
        `;
    }
}
