import { consume } from '@lit/context';
import { LitElement, TemplateResult, html } from 'lit';
import { Issue, reportFilingIssuesContext } from '../context/report-filings-issues-context';
import { PliInputProps } from 'pli/pli-input';
import {
    ReportFilingValidationMutation,
    ReportFilingValidation,
    reportFilingValidationContext,
    reportFilingValidationMutationContext,
    reportFilingStatus,
} from '../context/report-filings-payload-context';
import '../components/shared/report-filing-details-form-error-message';

import { GetReportFiling_Result, reportFilingResultContext } from '../context/report-filings-result-context';

type Constructor<T = {}> = new (...args: any[]) => T;

type ReportFilingValidationKey = keyof ReportFilingValidation;
export declare class ReportFilingFormSectionInterface {
    payload: ReportFilingValidation;
    data: GetReportFiling_Result;
    issues: Issue[];
    isFormEnabled: boolean;
    isInvalidField(key: ReportFilingValidationKey): boolean;
    setPayload: ReportFilingValidationMutation;
    getStateForField(key: ReportFilingValidationKey): PliInputProps['state'];
    renderErrorMessageForField(key: ReportFilingValidationKey): TemplateResult | null;
}

export const ReportFilingFormSectionMixin = <T extends Constructor<LitElement>>(superClass: T) => {
    class MixinClass extends superClass {
        @consume({ context: reportFilingIssuesContext, subscribe: true })
        issues: Issue[];

        @consume({ context: reportFilingValidationContext, subscribe: true })
        payload: ReportFilingValidation;

        @consume({ context: reportFilingResultContext, subscribe: true })
        data: GetReportFiling_Result;

        @consume({ context: reportFilingValidationMutationContext, subscribe: true })
        setPayload: ReportFilingValidationMutation;

        getStateForField = (key: ReportFilingValidationKey): PliInputProps['state'] => {
            const issue = this._getIssueByField(key);
            return Boolean(issue) ? 'invalid' : 'initial';
        };

        isInvalidField = (key: ReportFilingValidationKey): boolean => Boolean(this.issues?.find((i) => i.name === key));

        renderErrorMessageForField = (key: ReportFilingValidationKey): TemplateResult | null => {
            const issue = this._getIssueByField(key);
            return Boolean(issue)
                ? html`<report-filing-details-form-error-message
                      >${issue.message}</report-filing-details-form-error-message
                  >`
                : null;
        };

        get isFormEnabled() {
            return this.payload?.status === reportFilingStatus.enum.InProgress;
        }

        private _getIssueByField = (key: ReportFilingValidationKey) => this.issues?.find((issue) => issue.name === key);
    }

    return MixinClass as Constructor<ReportFilingFormSectionInterface> & T;
};

export const ReportFilingFormSection = ReportFilingFormSectionMixin(LitElement);
