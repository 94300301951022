import { css } from 'lit';

export const baseStyles = css`
    * {
        margin: 0;
        padding: 0;
        min-width: 0;
        box-sizing: border-box;
        text-decoration: none;
        font-family: inherit;
    }

    a {
        color: inherit;
    }

    a:hover {
        text-decoration: underline;
    }
`;
