import { TemplateResult, css, html } from 'lit';
import { defineHeaderItems } from 'pli/pli-table';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import { nameFormatter } from 'utils/name-formatter';
import { ReportFilingFormSection } from '../mixin';

import '../../../pli/pli-button';
import '../../../pli/pli-user-bubble';
import '../../../pli/pli-drag-drop';

import { customElement } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { styles } from 'pli/styles';
import { downloadContent } from 'utils/download-content';
import { consume } from '@lit/context';
import { FileUpdateEvent } from '../../../pli/pli-drag-drop';
import { UserModel } from 'user-model';
import { CurrentUser, currentUserContext } from 'context/current-user-context';
import {
    archiveReportFilingDocument,
    downloadReportFilingDocument,
    GetReportFiling_ReportFilingDocumentResult,
    uploadReportFilingDocument,
    UploadReportFilingDocument_Request,
} from 'tms-client';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';

@customElement('report-filing-details-documents')
class ReportFilingDetailsDocuments extends ReportFilingFormSection {
    static styles = [
        styles.grid,
        styles.flex,
        css`
            .empty-state {
                padding: var(--size-3) 0;
            }
        `,
    ];

    @consume({ context: currentUserContext })
    currentUser: CurrentUser;

    @consume({ context: errorDialogContext })
    errorDialog?: ErrorDialogController;

    _profile: UserModel = new UserModel();

    async connectedCallback() {
        super.connectedCallback();
        this._profile = await this.currentUser.get();
    }

    downloadDocument = async (document: GetReportFiling_ReportFilingDocumentResult) => {
        const { error, data } = await downloadReportFilingDocument({
            path: { documentId: document.documentId, id: this.data.reportFilingId },
        });

        if (error) {
            return this.errorDialog.showError({ title: `Could not download document ${document.name}` });
        }

        downloadContent(data, document.name);
    };

    onFilesUpdate = async (event: FileUpdateEvent) => {
        const { value } = event.detail;
        for (const file of value) {
            const fileDocumentRequest: UploadReportFilingDocument_Request = {
                file: file,
            };

            const { error, data } = await uploadReportFilingDocument({
                path: { id: this.data.reportFilingId },
                body: fileDocumentRequest,
            });
            if (error) {
                return this.errorDialog?.showError({ title: `Upload error for file: ${file.name}` });
            }

            const { type, name } = file;
            const addedDocument: GetReportFiling_ReportFilingDocumentResult = {
                contentType: type,
                name,
                documentId: data.documentId,
                uploadedBy: {
                    firstName: this._profile.firstName,
                    fullName: this._profile.firstName,
                    lastName: this._profile.lastName,
                    userId: this._profile.userId,
                },
                uploadedById: this._profile.userId,
                uploadedTime: new Date().toDateString(),
            };

            const documentsUpdated = [...this.data.documents, addedDocument];
            this.data = {
                ...this.data,
                documents: documentsUpdated,
            };
            this.requestUpdate();
        }
    };

    onFilesFail = (event: FileUpdateEvent) => {
        const { maximumFileSize } = event.detail;
        this.errorDialog.showError({
            title: 'File size limit reached',
            bodyText: `Attached file(s) exceeds our file size limit (${maximumFileSize} MB), please try to reduce the size of your file or try a different file. `,
        });
    };

    _renderRemoveDocumentDialog = (item: GetReportFiling_ReportFilingDocumentResult) => {
        const handleRemoveDocument = async (documentId: number) => {
            const { error } = await archiveReportFilingDocument({
                path: { id: this.data.reportFilingId, documentId: documentId },
                parseAs: 'stream',
            });

            if (error) {
                return this.errorDialog?.showError({ title: 'Could not remove document' });
            }
            const updatedDocuments = this.data.documents.filter((d) => documentId !== d.documentId);
            this.data = {
                ...this.data,
                documents: [...updatedDocuments],
            };
            this.requestUpdate();
        };

        return html`
            <pli-dialog>
                <pli-dialog-open-button
                    class="flex justify-end items-center"
                    slot="open-button"
                    variant="destructive"
                    width="hug-content"
                    ><pli-icon name="trash" slot="icon-left"></pli-icon>Remove</pli-dialog-open-button
                >

                <pli-dialog-content
                    ><pli-icon-box slot="icon" name="question-circle" color="blue"></pli-icon-box>
                    <pli-text variant="h3">Deleting document</pli-text>
                    <p>Are you sure you want to delete the file: ${item.name}? This action cannot be undone.</p>
                </pli-dialog-content>

                <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
                <pli-dialog-primary-button
                    @pli-dialog-primary-dismiss="${() => handleRemoveDocument(item.documentId)}"
                    variant="destructive"
                    slot="primary-button"
                    >Remove</pli-dialog-primary-button
                >
            </pli-dialog>
        `;
    };

    render() {
        const { isFormEnabled, payload, data, onFilesUpdate, onFilesFail, downloadDocument } = this;
        const headerItems = defineHeaderItems({
            Name: {
                sortField: 'Name',
                columnSpan: 3,
            },
            Date: {
                sortField: null,
                columnSpan: 3,
            },
            'Created by': {
                sortField: null,
                columnSpan: 3,
            },
            '': {
                sortField: null,
                columnSpan: 3,
            },
        });

        const renderItem = (item: GetReportFiling_ReportFilingDocumentResult): TemplateResult =>
            html` <tr>
                <td>
                    <pli-button variant="text" .onClick="${() => downloadDocument(item)}">
                        <pli-icon slot="icon-left" name="file-earmark-image"></pli-icon>
                        ${item.name}
                    </pli-button>
                </td>
                <td>${dateTimeFormatter(item.uploadedTime)}</td>
                <td>
                    <div class="flex items-center gap-1">
                        <div>
                            <pli-user-bubble
                                userId="${item.uploadedBy.userId}"
                                firstName="${item.uploadedBy.firstName}"
                                lastName="${item.uploadedBy.lastName}"
                            ></pli-user-bubble>
                        </div>
                        <pli-text
                            >${nameFormatter({
                                firstName: item.uploadedBy.firstName,
                                lastName: item.uploadedBy.lastName,
                                userId: item.uploadedBy.userId,
                            })}</pli-text
                        >
                    </div>
                </td>
                <td>
                    <div class="flex justify-end items-center">${this._renderRemoveDocumentDialog(item)}</div>
                </td>
            </tr>`;

        return html` <div>
            <div class="grid-vertical gap-2">
                ${when(
                    Boolean(data.documents.length),
                    () =>
                        html` <pli-table
                            .headerItems="${headerItems}"
                            .items="${data.documents}"
                            .renderTemplate="${renderItem}"
                        ></pli-table>`,
                    () => html`
                        <div class="grid empty-state">
                            <div class="col-start-4 col-span-6">
                                <pli-text as="p" variant="h4">No files or documents yet</pli-text>
                                <pli-text as="p"
                                    >However, its very easy to add new ones (Maximum file size: 10 MB)
                                </pli-text>
                            </div>
                        </div>
                    `,
                )}
                ${when(
                    isFormEnabled,
                    () => html`
                        <pli-drag-drop @filesUpdate="${onFilesUpdate}" @filesFail="${onFilesFail}"></pli-drag-drop>
                    `,
                )}
            </div>
        </div>`;
    }
}
