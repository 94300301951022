import { baseStyles } from './base-styles';
import { dividerStyles } from './divider-styles';
import { flexStyles } from './flex-styles';
import { formStyles } from './form-styles';
import { gridStyles } from './grid-styles';
import { listStyles } from './list-styles';
import { paddingStyles } from './padding-styles';
import { positionStyles } from './position-styles';
import { roundedStyles } from './rounded-styles';

export const styles = {
    base: baseStyles,
    flex: flexStyles,
    grid: gridStyles,
    rounded: roundedStyles,
    position: positionStyles,
    list: listStyles,
    form: formStyles,
    divider: dividerStyles,
    padding: paddingStyles,
};
