import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from 'pli/styles';
import '../../../../pli/pli-text';

@customElement('report-filings-details-form-section')
class FormSection extends LitElement {
    static styles = [
        styles.grid,
        styles.flex,
        styles.base,
        css`
            fieldset {
                appearance: none;
                border: 0;
            }
        `,
    ];

    @property()
    heading?: string;

    render() {
        const { heading } = this;
        return html`<fieldset class="grid-vertical gap-1">
            ${heading
                ? html`<div class="flex items-center gap-1">
                      <pli-text variant="h3">${heading}</pli-text><slot name="status-label"></slot>
                  </div>`
                : null}
            <slot></slot>
        </fieldset>`;
    }
}
