import { classMap } from 'lit/directives/class-map.js';

export const availableRadii = [0, 'sm', 'md', 'lg', 'full'] as const;
export type PliRoundedElementRadius = (typeof availableRadii)[number];
export type PliRoundedElementProps<T> = RadiusProps & T;

type RadiusProps = {
    radius?: PliRoundedElementRadius;
    radiusTopLeft?: PliRoundedElementRadius;
    radiusTopRight?: PliRoundedElementRadius;
    radiusBottomRight?: PliRoundedElementRadius;
    radiusBottomLeft?: PliRoundedElementRadius;
};
export const createRoundedElementClassnames = ({
    radius,
    radiusBottomLeft = 'md',
    radiusBottomRight = 'md',
    radiusTopLeft = 'md',
    radiusTopRight = 'md',
}: RadiusProps = {}) => {
    if (radius) {
        const raw = {
            [`rounded-${radius}`]: true,
        };
        return {
            raw,
            classNames: classMap(raw),
        };
    }

    const raw = {
        [`rounded-t-l-${radiusTopLeft}`]: true,
        [`rounded-t-r-${radiusTopRight}`]: true,
        [`rounded-b-r-${radiusBottomRight}`]: true,
        [`rounded-b-l-${radiusBottomLeft}`]: true,
    };

    return {
        raw,
        classNames: classMap(raw),
    };
};
